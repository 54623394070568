import React, { useEffect } from 'react';
import  { useLocation } from 'react-router-dom'

const Contratacao = () => {

  const location = useLocation();

  useEffect(() => {
    // Obtém a query string da localização atual
    const queryString = location.search;

    // Monta o novo URL de destino com a mesma query string
    const novoURL = `https://assine2.hostnet.com.br/contratacao/site${queryString}`;
    // Redireciona para o novo URL
    window.location.href = novoURL;
  }, [location]);

  return <div></div>
}

export default Contratacao;