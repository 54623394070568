import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Cadastro = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryString = queryParams.toString();

    navigate(`/?${queryString}`, { replace: true });
  }, [navigate, location]);

  return <div></div>;
}

export default Cadastro;