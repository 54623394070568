import React, { useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'

const Cupom = () => {

  const navigate = useNavigate();

  const query = new URLSearchParams(document.location.search);
  const nome = query.get('nome')
  const redirect = query.get('redirect');

  useEffect(() => {
    // adiciona o cookie
    if (nome) {
      var data = new Date();
      data.setTime(data.getTime() + (1/*dias*/ * 24 * 60 * 60 * 1000));
      var expires = "expires=" + data.toUTCString();
      var path = ";path=/;domain=hostnet.com.br";
      document.cookie = 'StCupomCodigo' + "=" + nome + ";" + expires + path;
    }

    if (redirect && /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/.test(redirect)) {
      if (redirect.includes(".hostnet.com.br")) {
        window.location.href = redirect;
      }
    }

    navigate('/', { replace: true });
  }, []);

  return <div></div>
}

export default Cupom;