import React from 'react';

const countries = [
  {
      "idPais": 1,
      "pais": "África do Sul",
      "sigla": "ZA",
      "continente": "África",
      "paisEn": "South Africa"
  },
  {
      "idPais": 2,
      "pais": "Albânia",
      "sigla": "AL",
      "continente": "Europa",
      "paisEn": "Albania"
  },
  {
      "idPais": 3,
      "pais": "Alemanha",
      "sigla": "DE",
      "continente": "Europa",
      "paisEn": "Germany"
  },
  {
      "idPais": 4,
      "pais": "Andorra",
      "sigla": "AD",
      "continente": "Europa",
      "paisEn": "Andorra"
  },
  {
      "idPais": 5,
      "pais": "Angola",
      "sigla": "AO",
      "continente": "África",
      "paisEn": "Angola"
  },
  {
      "idPais": 6,
      "pais": "Anguilla",
      "sigla": "AI",
      "continente": "América",
      "paisEn": "Anguilla"
  },
  {
      "idPais": 7,
      "pais": "Antígua e Barbuda",
      "sigla": "AG",
      "continente": "América",
      "paisEn": "Antigua and Barbuda"
  },
  {
      "idPais": 8,
      "pais": "Arábia Saudita",
      "sigla": "SA",
      "continente": "Ásia",
      "paisEn": "Saudi Arabia"
  },
  {
      "idPais": 9,
      "pais": "Argentina",
      "sigla": "AR",
      "continente": "América",
      "paisEn": "Argentina"
  },
  {
      "idPais": 10,
      "pais": "Armênia",
      "sigla": "AM",
      "continente": "Ásia",
      "paisEn": "Armenia"
  },
  {
      "idPais": 11,
      "pais": "Aruba",
      "sigla": "AW",
      "continente": "América",
      "paisEn": "Aruba"
  },
  {
      "idPais": 12,
      "pais": "Austrália",
      "sigla": "AU",
      "continente": "Oceania",
      "paisEn": "Australia"
  },
  {
      "idPais": 13,
      "pais": "Áustria",
      "sigla": "AT",
      "continente": "Europa",
      "paisEn": "Austria"
  },
  {
      "idPais": 14,
      "pais": "Azerbaijão",
      "sigla": "AZ",
      "continente": "Ásia",
      "paisEn": "Azerbaijan"
  },
  {
      "idPais": 15,
      "pais": "Bahamas",
      "sigla": "BS",
      "continente": "América",
      "paisEn": "Bahamas"
  },
  {
      "idPais": 16,
      "pais": "Bahrein",
      "sigla": "BH",
      "continente": "Ásia",
      "paisEn": "Bahrain"
  },
  {
      "idPais": 17,
      "pais": "Bangladesh",
      "sigla": "BD",
      "continente": "Ásia",
      "paisEn": "Bangladesh"
  },
  {
      "idPais": 18,
      "pais": "Barbados",
      "sigla": "BB",
      "continente": "América",
      "paisEn": "Barbados"
  },
  {
      "idPais": 19,
      "pais": "Bélgica",
      "sigla": "BE",
      "continente": "Europa",
      "paisEn": "Belgium"
  },
  {
      "idPais": 20,
      "pais": "Benin",
      "sigla": "BJ",
      "continente": "África",
      "paisEn": "Benin"
  },
  {
      "idPais": 21,
      "pais": "Bermudas",
      "sigla": "BM",
      "continente": "América",
      "paisEn": "Bermuda"
  },
  {
      "idPais": 22,
      "pais": "Botsuana",
      "sigla": "BW",
      "continente": "África",
      "paisEn": "Botswana"
  },
  {
      "idPais": 23,
      "pais": "Brasil",
      "sigla": "BR",
      "continente": "América",
      "paisEn": "Brazil"
  },
  {
      "idPais": 24,
      "pais": "Brunei",
      "sigla": "BN",
      "continente": "Ásia",
      "paisEn": "Brunei"
  },
  {
      "idPais": 25,
      "pais": "Bulgária",
      "sigla": "BG",
      "continente": "Europa",
      "paisEn": "Bulgaria"
  },
  {
      "idPais": 26,
      "pais": "Burkina Faso",
      "sigla": "BF",
      "continente": "África",
      "paisEn": "Burkina Faso"
  },
  {
      "idPais": 27,
      "pais": "Butão",
      "sigla": "BT",
      "continente": "Ásia",
      "paisEn": "Bhutan"
  },
  {
      "idPais": 28,
      "pais": "Cabo Verde",
      "sigla": "CV",
      "continente": "África",
      "paisEn": "Cape Verde"
  },
  {
      "idPais": 29,
      "pais": "Camarões",
      "sigla": "CM",
      "continente": "África",
      "paisEn": "Cameroon"
  },
  {
      "idPais": 30,
      "pais": "Camboja",
      "sigla": "KH",
      "continente": "Ásia",
      "paisEn": "Cambodia"
  },
  {
      "idPais": 31,
      "pais": "Canadá",
      "sigla": "CA",
      "continente": "América",
      "paisEn": "Canada"
  },
  {
      "idPais": 32,
      "pais": "Cazaquistão",
      "sigla": "KZ",
      "continente": "Ásia",
      "paisEn": "Kazakhstan"
  },
  {
      "idPais": 33,
      "pais": "Chade",
      "sigla": "TD",
      "continente": "África",
      "paisEn": "Chad"
  },
  {
      "idPais": 34,
      "pais": "Chile",
      "sigla": "CL",
      "continente": "América",
      "paisEn": "Chile"
  },
  {
      "idPais": 35,
      "pais": "China",
      "sigla": "CN",
      "continente": "Ásia",
      "paisEn": "China"
  },
  {
      "idPais": 36,
      "pais": "Vaticano",
      "sigla": "VA",
      "continente": "Europa",
      "paisEn": "Vatican"
  },
  {
      "idPais": 37,
      "pais": "Colômbia",
      "sigla": "CO",
      "continente": "América",
      "paisEn": "Colombia"
  },
  {
      "idPais": 38,
      "pais": "Congo",
      "sigla": "CD",
      "continente": "África",
      "paisEn": "Democratic Republic of the Congo"
  },
  {
      "idPais": 39,
      "pais": "Coréia do Sul",
      "sigla": "KR",
      "continente": "Ásia",
      "paisEn": "South Korea"
  },
  {
      "idPais": 40,
      "pais": "Costa do Marfim",
      "sigla": "CI",
      "continente": "África",
      "paisEn": "Ivory Coast"
  },
  {
      "idPais": 41,
      "pais": "Costa Rica",
      "sigla": "CR",
      "continente": "América",
      "paisEn": "Costa Rica"
  },
  {
      "idPais": 42,
      "pais": "Croácia",
      "sigla": "HR",
      "continente": "Europa",
      "paisEn": "Croatia"
  },
  {
      "idPais": 43,
      "pais": "Dinamarca",
      "sigla": "DK",
      "continente": "Europa",
      "paisEn": "Denmark"
  },
  {
      "idPais": 44,
      "pais": "Djibuti",
      "sigla": "DJ",
      "continente": "África",
      "paisEn": "Djibouti"
  },
  {
      "idPais": 45,
      "pais": "Dominica",
      "sigla": "DM",
      "continente": "América",
      "paisEn": "Dominica"
  },
  {
      "idPais": 46,
      "pais": "Estados Unidos da América",
      "sigla": "US",
      "continente": "América",
      "paisEn": "United States"
  },
  {
      "idPais": 47,
      "pais": "Egito",
      "sigla": "EG",
      "continente": "África",
      "paisEn": "Egypt"
  },
  {
      "idPais": 48,
      "pais": "El Salvador",
      "sigla": "SV",
      "continente": "América",
      "paisEn": "El Salvador"
  },
  {
      "idPais": 49,
      "pais": "Emirados Árabes Unidos",
      "sigla": "AE",
      "continente": "Ásia",
      "paisEn": "United Arab Emirates"
  },
  {
      "idPais": 50,
      "pais": "Equador",
      "sigla": "EC",
      "continente": "América",
      "paisEn": "Ecuador"
  },
  {
      "idPais": 51,
      "pais": "Eritréia",
      "sigla": "ER",
      "continente": "África",
      "paisEn": "Eritréia"
  },
  {
      "idPais": 52,
      "pais": "Escócia",
      "sigla": "SL",
      "continente": "Europa",
      "paisEn": "Scotland"
  },
  {
      "idPais": 53,
      "pais": "Eslováquia",
      "sigla": "SK",
      "continente": "Europa",
      "paisEn": "Slovakia"
  },
  {
      "idPais": 54,
      "pais": "Eslovênia",
      "sigla": "SI",
      "continente": "Europa",
      "paisEn": "Slovenia"
  },
  {
      "idPais": 55,
      "pais": "Espanha",
      "sigla": "ES",
      "continente": "Europa",
      "paisEn": "Spain"
  },
  {
      "idPais": 56,
      "pais": "Estônia",
      "sigla": "EE",
      "continente": "Europa",
      "paisEn": "Estonia"
  },
  {
      "idPais": 57,
      "pais": "Etiópia",
      "sigla": "ET",
      "continente": "África",
      "paisEn": "Ethiopia"
  },
  {
      "idPais": 58,
      "pais": "Fiji",
      "sigla": "FJ",
      "continente": "Oceania",
      "paisEn": "Fiji"
  },
  {
      "idPais": 59,
      "pais": "Filipinas",
      "sigla": "PH",
      "continente": "Ásia",
      "paisEn": "Philippines"
  },
  {
      "idPais": 60,
      "pais": "Finlândia",
      "sigla": "FI",
      "continente": "Europa",
      "paisEn": "Finland"
  },
  {
      "idPais": 61,
      "pais": "França",
      "sigla": "FR",
      "continente": "Europa",
      "paisEn": "France"
  },
  {
      "idPais": 62,
      "pais": "Gabão",
      "sigla": "GA",
      "continente": "África",
      "paisEn": "Gabon"
  },
  {
      "idPais": 63,
      "pais": "Gâmbia",
      "sigla": "GM",
      "continente": "África",
      "paisEn": "Gambia"
  },
  {
      "idPais": 64,
      "pais": "Gana",
      "sigla": "GH",
      "continente": "África",
      "paisEn": "Ghana"
  },
  {
      "idPais": 65,
      "pais": "Geórgia",
      "sigla": "GE",
      "continente": "Europa",
      "paisEn": "Georgia"
  },
  {
      "idPais": 66,
      "pais": "Gibraltar",
      "sigla": "GI",
      "continente": "Europa",
      "paisEn": "Gibraltar"
  },
  {
      "idPais": 67,
      "pais": "Granada",
      "sigla": "GD",
      "continente": "América",
      "paisEn": "Grenada"
  },
  {
      "idPais": 68,
      "pais": "Grécia",
      "sigla": "GR",
      "continente": "Europa",
      "paisEn": "Greece"
  },
  {
      "idPais": 70,
      "pais": "Guam",
      "sigla": "GU",
      "continente": "Oceania",
      "paisEn": "Guam"
  },
  {
      "idPais": 71,
      "pais": "Guatemala",
      "sigla": "GT",
      "continente": "América",
      "paisEn": "Guatemala"
  },
  {
      "idPais": 72,
      "pais": "Guiana",
      "sigla": "GY",
      "continente": "América",
      "paisEn": "Guyana"
  },
  {
      "idPais": 73,
      "pais": "Guiana Francesa",
      "sigla": "GF",
      "continente": "América",
      "paisEn": "French Guiana"
  },
  {
      "idPais": 74,
      "pais": "Guiné-Bissau",
      "sigla": "GW",
      "continente": "África",
      "paisEn": "Guinea-Bissau"
  },
  {
      "idPais": 75,
      "pais": "Haiti",
      "sigla": "HT",
      "continente": "América",
      "paisEn": "Haiti"
  },
  {
      "idPais": 76,
      "pais": "Países Baixos",
      "sigla": "NL",
      "continente": "Europa",
      "paisEn": "Netherlands"
  },
  {
      "idPais": 77,
      "pais": "Honduras",
      "sigla": "HN",
      "continente": "América",
      "paisEn": "Honduras"
  },
  {
      "idPais": 78,
      "pais": "Hong Kong",
      "sigla": "HK",
      "continente": "Ásia",
      "paisEn": "Hong Kong"
  },
  {
      "idPais": 79,
      "pais": "Hungria",
      "sigla": "HU",
      "continente": "Europa",
      "paisEn": "Hungary"
  },
  {
      "idPais": 80,
      "pais": "Iêmen",
      "sigla": "YE",
      "continente": "Ásia",
      "paisEn": "Yemen"
  },
  {
      "idPais": 81,
      "pais": "Ilhas Cayman",
      "sigla": "KY",
      "continente": "América",
      "paisEn": "Cayman Islands"
  },
  {
      "idPais": 82,
      "pais": "Ilhas Cook",
      "sigla": "CK",
      "continente": "Oceania",
      "paisEn": "Cook Islands"
  },
  {
      "idPais": 84,
      "pais": "Ilhas Marshall",
      "sigla": "MH",
      "continente": "Oceania",
      "paisEn": "Marshall Islands"
  },
  {
      "idPais": 85,
      "pais": "Ilhas Turks e Caicos",
      "sigla": "TC",
      "continente": "América",
      "paisEn": "Turks and Caicos Islands"
  },
  {
      "idPais": 86,
      "pais": "Ilhas Virgens Americanas",
      "sigla": "VI",
      "continente": "América",
      "paisEn": "United States Virgin Islands"
  },
  {
      "idPais": 87,
      "pais": "Ilhas Virgens Britânicas",
      "sigla": "VG",
      "continente": "América",
      "paisEn": "British Virgin Islands"
  },
  {
      "idPais": 88,
      "pais": "Ilhas Wallis e Futuna",
      "sigla": "WF",
      "continente": "Oceania",
      "paisEn": "Wallis and Futuna"
  },
  {
      "idPais": 89,
      "pais": "Índia",
      "sigla": "IN",
      "continente": "Ásia",
      "paisEn": "India"
  },
  {
      "idPais": 90,
      "pais": "Indonésia",
      "sigla": "ID",
      "continente": "Ásia",
      "paisEn": "Indonesia"
  },
  {
      "idPais": 91,
      "pais": "Inglaterra",
      "sigla": "EN",
      "continente": "Europa",
      "paisEn": "England"
  },
  {
      "idPais": 92,
      "pais": "Irlanda",
      "sigla": "IE",
      "continente": "Europa",
      "paisEn": "Ireland"
  },
  {
      "idPais": 93,
      "pais": "Islândia",
      "sigla": "IS",
      "continente": "Europa",
      "paisEn": "Iceland"
  },
  {
      "idPais": 94,
      "pais": "Israel",
      "sigla": "IL",
      "continente": "Ásia",
      "paisEn": "Israel"
  },
  {
      "idPais": 95,
      "pais": "Itália",
      "sigla": "IT",
      "continente": "Europa",
      "paisEn": "Italy"
  },
  {
      "idPais": 96,
      "pais": "Jamaica",
      "sigla": "JM",
      "continente": "África",
      "paisEn": "Jamaica"
  },
  {
      "idPais": 97,
      "pais": "Japão",
      "sigla": "JP",
      "continente": "Ásia",
      "paisEn": "Japan"
  },
  {
      "idPais": 98,
      "pais": "Jordânia",
      "sigla": "JO",
      "continente": "Ásia",
      "paisEn": "Jordan"
  },
  {
      "idPais": 99,
      "pais": "Kuwait",
      "sigla": "KW",
      "continente": "Ásia",
      "paisEn": "Kuwait"
  },
  {
      "idPais": 100,
      "pais": "Letônia",
      "sigla": "LV",
      "continente": "Europa",
      "paisEn": "Latvia"
  },
  {
      "idPais": 101,
      "pais": "Líbano",
      "sigla": "LB",
      "continente": "Ásia",
      "paisEn": "Lebanon"
  },
  {
      "idPais": 102,
      "pais": "Liechtenstein",
      "sigla": "LI",
      "continente": "Europa",
      "paisEn": "Liechtenstein"
  },
  {
      "idPais": 103,
      "pais": "Lituânia",
      "sigla": "LT",
      "continente": "Europa",
      "paisEn": "Lithuania"
  },
  {
      "idPais": 104,
      "pais": "Luxemburgo",
      "sigla": "LU",
      "continente": "Europa",
      "paisEn": "Luxembourg"
  },
  {
      "idPais": 105,
      "pais": "Macau",
      "sigla": "MO",
      "continente": "Ásia",
      "paisEn": "Macau"
  },
  {
      "idPais": 106,
      "pais": "Macedônia",
      "sigla": "MK",
      "continente": "Europa",
      "paisEn": "Macedonia"
  },
  {
      "idPais": 107,
      "pais": "Madagascar",
      "sigla": "MG",
      "continente": "África",
      "paisEn": "Madagascar"
  },
  {
      "idPais": 108,
      "pais": "Malásia",
      "sigla": "MY",
      "continente": "Ásia",
      "paisEn": "Malaysia"
  },
  {
      "idPais": 109,
      "pais": "Malawi",
      "sigla": "MW",
      "continente": "África",
      "paisEn": "Malawi"
  },
  {
      "idPais": 110,
      "pais": "Mali",
      "sigla": "ML",
      "continente": "África",
      "paisEn": "Mali"
  },
  {
      "idPais": 111,
      "pais": "Malta",
      "sigla": "MT",
      "continente": "Europa",
      "paisEn": "Malta"
  },
  {
      "idPais": 112,
      "pais": "Marrocos",
      "sigla": "MA",
      "continente": "África",
      "paisEn": "Morocco"
  },
  {
      "idPais": 113,
      "pais": "Martinica",
      "sigla": "MQ",
      "continente": "América",
      "paisEn": "Martinique"
  },
  {
      "idPais": 114,
      "pais": "Mauritânia",
      "sigla": "MR",
      "continente": "África",
      "paisEn": "Mauritania"
  },
  {
      "idPais": 115,
      "pais": "Maurício",
      "sigla": "MU",
      "continente": "África",
      "paisEn": "Mauritius"
  },
  {
      "idPais": 116,
      "pais": "México",
      "sigla": "MX",
      "continente": "América",
      "paisEn": "Mexico"
  },
  {
      "idPais": 117,
      "pais": "Moldávia",
      "sigla": "MD",
      "continente": "Europa",
      "paisEn": "Moldova"
  },
  {
      "idPais": 118,
      "pais": "Mônaco",
      "sigla": "MC",
      "continente": "Europa",
      "paisEn": "Monaco"
  },
  {
      "idPais": 119,
      "pais": "Montserrat",
      "sigla": "MS",
      "continente": "América",
      "paisEn": "Montserrat"
  },
  {
      "idPais": 120,
      "pais": "Nepal",
      "sigla": "NP",
      "continente": "Ásia",
      "paisEn": "Nepal"
  },
  {
      "idPais": 121,
      "pais": "Nicaraguá",
      "sigla": "NI",
      "continente": "América",
      "paisEn": "Nicaragua"
  },
  {
      "idPais": 122,
      "pais": "Niger",
      "sigla": "NE",
      "continente": "África",
      "paisEn": "Niger"
  },
  {
      "idPais": 123,
      "pais": "Nigéria",
      "sigla": "NG",
      "continente": "África",
      "paisEn": "Nigeria"
  },
  {
      "idPais": 124,
      "pais": "Noruega",
      "sigla": "NO",
      "continente": "Europa",
      "paisEn": "Norway"
  },
  {
      "idPais": 125,
      "pais": "Nova Caledônia",
      "sigla": "NC",
      "continente": "Oceania",
      "paisEn": "New Caledonia"
  },
  {
      "idPais": 126,
      "pais": "Nova Zelândia",
      "sigla": "NZ",
      "continente": "Oceania",
      "paisEn": "New Zealand"
  },
  {
      "idPais": 127,
      "pais": "Omã",
      "sigla": "OM",
      "continente": "Ásia",
      "paisEn": "Oman"
  },
  {
      "idPais": 128,
      "pais": "Palau",
      "sigla": "PW",
      "continente": "Oceania",
      "paisEn": "Palau"
  },
  {
      "idPais": 129,
      "pais": "Panamá",
      "sigla": "PA",
      "continente": "América",
      "paisEn": "Panama"
  },
  {
      "idPais": 130,
      "pais": "Papua-Nova Guiné",
      "sigla": "PG",
      "continente": "Oceania",
      "paisEn": "Papua New Guinea"
  },
  {
      "idPais": 131,
      "pais": "Paquistão",
      "sigla": "PK",
      "continente": "Ásia",
      "paisEn": "Pakistan"
  },
  {
      "idPais": 132,
      "pais": "Peru",
      "sigla": "PE",
      "continente": "América",
      "paisEn": "Peru"
  },
  {
      "idPais": 133,
      "pais": "Polinésia Francesa",
      "sigla": "PF",
      "continente": "Oceania",
      "paisEn": "French Polynesia"
  },
  {
      "idPais": 134,
      "pais": "Polônia",
      "sigla": "PL",
      "continente": "Europa",
      "paisEn": "Poland"
  },
  {
      "idPais": 135,
      "pais": "Porto Rico",
      "sigla": "PR",
      "continente": "América",
      "paisEn": "Puerto Rico"
  },
  {
      "idPais": 136,
      "pais": "Portugal",
      "sigla": "PT",
      "continente": "Europa",
      "paisEn": "Portugal"
  },
  {
      "idPais": 137,
      "pais": "Catar",
      "sigla": "QA",
      "continente": "Ásia",
      "paisEn": "Qatar"
  },
  {
      "idPais": 138,
      "pais": "Quênia",
      "sigla": "KE",
      "continente": "África",
      "paisEn": "Kenya"
  },
  {
      "idPais": 139,
      "pais": "República Dominicana",
      "sigla": "DO",
      "continente": "América",
      "paisEn": "Dominican Republic"
  },
  {
      "idPais": 140,
      "pais": "República Tcheca",
      "sigla": "CZ",
      "continente": "Europa",
      "paisEn": "Czech Republic"
  },
  {
      "idPais": 141,
      "pais": "Reunião",
      "sigla": "RE",
      "continente": "África",
      "paisEn": "Reunion"
  },
  {
      "idPais": 142,
      "pais": "Romênia",
      "sigla": "RO",
      "continente": "Europa",
      "paisEn": "Romania"
  },
  {
      "idPais": 143,
      "pais": "Ruanda",
      "sigla": "RW",
      "continente": "África",
      "paisEn": "Rwanda"
  },
  {
      "idPais": 144,
      "pais": "Rússia",
      "sigla": "RU",
      "continente": "Europa",
      "paisEn": "Russia"
  },
  {
      "idPais": 146,
      "pais": "Samoa Americana",
      "sigla": "AS",
      "continente": "África",
      "paisEn": "American Samoa"
  },
  {
      "idPais": 147,
      "pais": "Senegal",
      "sigla": "SN",
      "continente": "África",
      "paisEn": "Senegal"
  },
  {
      "idPais": 148,
      "pais": "Serra Leoa",
      "sigla": "SL",
      "continente": "África",
      "paisEn": "Sierra Leone"
  },
  {
      "idPais": 149,
      "pais": "Seychelles",
      "sigla": "SC",
      "continente": "África",
      "paisEn": "Seychelles"
  },
  {
      "idPais": 150,
      "pais": "Singapura",
      "sigla": "SG",
      "continente": "Ásia",
      "paisEn": "Singapore"
  },
  {
      "idPais": 151,
      "pais": "Síria",
      "sigla": "SY",
      "continente": "Ásia",
      "paisEn": "Syria"
  },
  {
      "idPais": 152,
      "pais": "Sri Lanka",
      "sigla": "LK",
      "continente": "Ásia",
      "paisEn": "Sri Lanka"
  },
  {
      "idPais": 153,
      "pais": "São Cristóvão e Nevis",
      "sigla": "KN",
      "continente": "América",
      "paisEn": "Saint Kitts and Nevis"
  },
  {
      "idPais": 154,
      "pais": "Santa Lúcia",
      "sigla": "LC",
      "continente": "América",
      "paisEn": "Saint Lucia"
  },
  {
      "idPais": 155,
      "pais": "São Vicente e Granadinas",
      "sigla": "VC",
      "continente": "América",
      "paisEn": "Saint Vincent and the Grenadines"
  },
  {
      "idPais": 156,
      "pais": "Sudão",
      "sigla": "SD",
      "continente": "África",
      "paisEn": "Sudan"
  },
  {
      "idPais": 157,
      "pais": "Suécia",
      "sigla": "SE",
      "continente": "Europa",
      "paisEn": "Sweden"
  },
  {
      "idPais": 158,
      "pais": "Suiça",
      "sigla": "CH",
      "continente": "Europa",
      "paisEn": "Switzerland"
  },
  {
      "idPais": 159,
      "pais": "Suriname",
      "sigla": "SR",
      "continente": "América",
      "paisEn": "Suriname"
  },
  {
      "idPais": 160,
      "pais": "Tailândia",
      "sigla": "TH",
      "continente": "Ásia",
      "paisEn": "Thailand"
  },
  {
      "idPais": 161,
      "pais": "Taiwan",
      "sigla": "TW",
      "continente": "Ásia",
      "paisEn": "Taiwan"
  },
  {
      "idPais": 162,
      "pais": "Tanzânia",
      "sigla": "TZ",
      "continente": "África",
      "paisEn": "Tanzania"
  },
  {
      "idPais": 163,
      "pais": "Togo",
      "sigla": "TG",
      "continente": "África",
      "paisEn": "Togo"
  },
  {
      "idPais": 164,
      "pais": "Trindade e Tobago",
      "sigla": "TT",
      "continente": "América",
      "paisEn": "Trinidad and Tobago"
  },
  {
      "idPais": 165,
      "pais": "Tunísia",
      "sigla": "TN",
      "continente": "África",
      "paisEn": "Tunisia"
  },
  {
      "idPais": 166,
      "pais": "Turquia",
      "sigla": "TR",
      "continente": "Europa",
      "paisEn": "Turkey"
  },
  {
      "idPais": 167,
      "pais": "Ucrânia",
      "sigla": "UA",
      "continente": "Europa",
      "paisEn": "Ukraine"
  },
  {
      "idPais": 168,
      "pais": "Uganda",
      "sigla": "UG",
      "continente": "África",
      "paisEn": "Uganda"
  },
  {
      "idPais": 169,
      "pais": "Uruguai",
      "sigla": "UY",
      "continente": "América",
      "paisEn": "Uruguay"
  },
  {
      "idPais": 170,
      "pais": "Venezuela",
      "sigla": "VE",
      "continente": "América",
      "paisEn": "Venezuela"
  },
  {
      "idPais": 171,
      "pais": "Vietnã",
      "sigla": "VN",
      "continente": "Ásia",
      "paisEn": "Vietnam"
  },
  {
      "idPais": 173,
      "pais": "Zâmbia",
      "sigla": "ZM",
      "continente": "África",
      "paisEn": "Zambia"
  },
  {
      "idPais": 174,
      "pais": "Zimbábue",
      "sigla": "ZW",
      "continente": "África",
      "paisEn": "Zimbabwe"
  },
  {
      "idPais": 175,
      "pais": "Argélia",
      "sigla": "DZ",
      "continente": "África",
      "paisEn": "Algeria"
  },
  {
      "idPais": 176,
      "pais": "Belize",
      "sigla": "BZ",
      "continente": "América",
      "paisEn": "Belize"
  },
  {
      "idPais": 177,
      "pais": "Belarus",
      "sigla": "BY",
      "continente": "Europa",
      "paisEn": "Belarus"
  },
  {
      "idPais": 178,
      "pais": "Bolívia",
      "sigla": "BO",
      "continente": "América",
      "paisEn": "Bolivia"
  },
  {
      "idPais": 179,
      "pais": "Bósnia Herzegóvina",
      "sigla": "BA",
      "continente": "Europa",
      "paisEn": "Bosnia and Herzegovina"
  },
  {
      "idPais": 180,
      "pais": "Burundi",
      "sigla": "BI",
      "continente": "África",
      "paisEn": "Burundi"
  },
  {
      "idPais": 181,
      "pais": "Chipre",
      "sigla": "CY",
      "continente": "Europa",
      "paisEn": "Cyprus"
  },
  {
      "idPais": 183,
      "pais": "Comores",
      "sigla": "KM",
      "continente": "África",
      "paisEn": "Comoros"
  },
  {
      "idPais": 184,
      "pais": "Coréia do Norte",
      "sigla": "KP",
      "continente": "Ásia",
      "paisEn": "North Korea"
  },
  {
      "idPais": 185,
      "pais": "Cuba",
      "sigla": "CU",
      "continente": "América",
      "paisEn": "Cuba"
  },
  {
      "idPais": 187,
      "pais": "Guiné",
      "sigla": "GN",
      "continente": "África",
      "paisEn": "Guinea"
  },
  {
      "idPais": 188,
      "pais": "Guiné Equatorial",
      "sigla": "GQ",
      "continente": "África",
      "paisEn": "Equatorial Guinea"
  },
  {
      "idPais": 189,
      "pais": "Ilhas Salomão",
      "sigla": "SB",
      "continente": "Oceania",
      "paisEn": "Solomon Islands"
  },
  {
      "idPais": 190,
      "pais": "Irã",
      "sigla": "IR",
      "continente": "Ásia",
      "paisEn": "Iran"
  },
  {
      "idPais": 191,
      "pais": "Iraque",
      "sigla": "IQ",
      "continente": "Ásia",
      "paisEn": "Iraq"
  },
  {
      "idPais": 192,
      "pais": "Irlanda do Norte",
      "sigla": "NI",
      "continente": "Europa",
      "paisEn": "Northern Ireland"
  },
  {
      "idPais": 193,
      "pais": "Kiribati",
      "sigla": "KI",
      "continente": "Oceania",
      "paisEn": "Kiribati"
  },
  {
      "idPais": 194,
      "pais": "Laos",
      "sigla": "LA",
      "continente": "Ásia",
      "paisEn": "Laos"
  },
  {
      "idPais": 195,
      "pais": "Lesoto",
      "sigla": "LS",
      "continente": "África",
      "paisEn": "Lesotho"
  },
  {
      "idPais": 197,
      "pais": "Libéria",
      "sigla": "LR",
      "continente": "África",
      "paisEn": "Liberia"
  },
  {
      "idPais": 198,
      "pais": "Líbia",
      "sigla": "LY",
      "continente": "África",
      "paisEn": "Libya"
  },
  {
      "idPais": 199,
      "pais": "Maldivas",
      "sigla": "MV",
      "continente": "Ásia",
      "paisEn": "Maldives"
  },
  {
      "idPais": 200,
      "pais": "Mianmar",
      "sigla": "MM",
      "continente": "Ásia",
      "paisEn": "Myanmar"
  },
  {
      "idPais": 201,
      "pais": "Estados Federados da Micronésia",
      "sigla": "FM",
      "continente": "Oceania",
      "paisEn": "Federated States of Micronesia"
  },
  {
      "idPais": 202,
      "pais": "Moçambique",
      "sigla": "MZ",
      "continente": "África",
      "paisEn": "Mozambique"
  },
  {
      "idPais": 203,
      "pais": "Mongólia",
      "sigla": "MN",
      "continente": "Ásia",
      "paisEn": "Mongolia"
  },
  {
      "idPais": 204,
      "pais": "Namíbia",
      "sigla": "NA",
      "continente": "África",
      "paisEn": "Namibia"
  },
  {
      "idPais": 205,
      "pais": "Nauru",
      "sigla": "NR",
      "continente": "Oceania",
      "paisEn": "Nauru"
  },
  {
      "idPais": 206,
      "pais": "Paraguai",
      "sigla": "PY",
      "continente": "América",
      "paisEn": "Paraguay"
  },
  {
      "idPais": 207,
      "pais": "Quirguistão",
      "sigla": "KG",
      "continente": "Ásia",
      "paisEn": "Kyrgyzstan"
  },
  {
      "idPais": 208,
      "pais": "República Centro-Africana",
      "sigla": "CF",
      "continente": "África",
      "paisEn": "Central African Republic"
  },
  {
      "idPais": 209,
      "pais": "Samoa",
      "sigla": "WS",
      "continente": "Oceania",
      "paisEn": "Samoa"
  },
  {
      "idPais": 210,
      "pais": "San Marino",
      "sigla": "SM",
      "continente": "Europa",
      "paisEn": "San Marino"
  },
  {
      "idPais": 211,
      "pais": "São Tomé e Príncipe",
      "sigla": "ST",
      "continente": "África",
      "paisEn": "Saint Tome and Principe"
  },
  {
      "idPais": 212,
      "pais": "Sérvia e Montenegro",
      "sigla": "RS",
      "continente": "Europa",
      "paisEn": "Serbia"
  },
  {
      "idPais": 213,
      "pais": "Somália",
      "sigla": "SO",
      "continente": "África",
      "paisEn": "Somalia"
  },
  {
      "idPais": 214,
      "pais": "Suazilândia",
      "sigla": "SZ",
      "continente": "África",
      "paisEn": "Swaziland"
  },
  {
      "idPais": 215,
      "pais": "Tadjiquistão",
      "sigla": "TJ",
      "continente": "Ásia",
      "paisEn": "Tajikistan"
  },
  {
      "idPais": 216,
      "pais": "Tonga",
      "sigla": "TO",
      "continente": "Oceania",
      "paisEn": "Tonga"
  },
  {
      "idPais": 217,
      "pais": "Turquemenistão",
      "sigla": "TM",
      "continente": "Ásia",
      "paisEn": "Turkmenistan"
  },
  {
      "idPais": 218,
      "pais": "Tuvalu",
      "sigla": "TV",
      "continente": "Oceania",
      "paisEn": "Tuvalu"
  },
  {
      "idPais": 219,
      "pais": "Uzbequistão",
      "sigla": "UZ",
      "continente": "Ásia",
      "paisEn": "Uzbekistan"
  },
  {
      "idPais": 220,
      "pais": "Vanuatu",
      "sigla": "VU",
      "continente": "Oceania",
      "paisEn": "Vanuatu"
  },
  {
      "idPais": 221,
      "pais": "Afeganistão",
      "sigla": "AF",
      "continente": "Ásia",
      "paisEn": "Afghanistan"
  },
  {
      "idPais": 222,
      "pais": "Curaçao",
      "sigla": "CW",
      "continente": "América",
      "paisEn": "Curacao"
  },
  {
      "idPais": 223,
      "pais": "Timor-Leste",
      "sigla": "TL",
      "continente": "Ásia",
      "paisEn": "East Timor"
  },
  {
      "idPais": 224,
      "pais": "Ilhas Malvinas",
      "sigla": "FK",
      "continente": "América",
      "paisEn": "Falkland Islands"
  },
  {
      "idPais": 225,
      "pais": "Ilhas Feroe",
      "sigla": "FO",
      "continente": "Europa",
      "paisEn": "Faroe Islands"
  }   
]

export default countries;